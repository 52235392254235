import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Translation from 'components/data/Translation';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Dialog from 'components/ui-components/Dialog';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import { CreativeEditorV2 as CSCreativeEditorV2, DisabledFeature } from '../types/creativeEditorV2.type';
import { CreativeV2EditorHeader } from './header';
import Sidebar from './sidebar';
import { CreativeEditorV2Content } from './content';
import { CreativeV2Template } from '../types/creativeV2.type';
import { FormatListDrawer } from './format-list-drawer';
import '../styles/main.scss';

interface ComponentStoreProps {
    creativeType: CSCreativeEditorV2['creative']['type'];
    creativeTemplateIdentifier: CSCreativeEditorV2['creative']['data']['templateIdentifier'];
    templateLoaded: CSCreativeEditorV2['templateLoaded'];
}

interface Props {
    creativeV2Template?: CreativeV2Template;
    externalData?: any;
    disabledFeatures?: DisabledFeature[];
    onSaveCreative?: (creative: CreativeV2Template) => void;
    onClose?: () => void;
}

const CreativeEditorV2: React.FC<Props> = ({ creativeV2Template, externalData, disabledFeatures = ['languages'], onClose, onSaveCreative }) => {
    const [showCloseConfirmDialog, setShowCloseConfirmDialog] = useState(false);

    const { creativeType, creativeTemplateIdentifier, templateLoaded } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creativeType: 'creative.type',
            creativeTemplateIdentifier: 'creative.data.templateIdentifier',
            templateLoaded: 'templateLoaded',
            disabledFeatures: 'disabledFeatures'
        }
    });

    useEffect(() => {
        return () => {
            ComponentStore.remove('CreativeEditorV2');
        };
    }, []);

    useEffect(() => {
        setInitialData();
    }, [creativeV2Template?.type]);

    useEffect(() => {
        // Create dialog to check if the user wants to leave the page.
        window.addEventListener('beforeunload', beforeUnloadHandler);
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);

    /**
     * Prevent the user from leaving the page without saving.
     * @param event - The beforeunload event.
     */
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
        const changedAfterSave = ComponentStoreHelpers.getItem('CreativeEditorV2', 'changedAfterSave');
        if (!changedAfterSave) return;
        event.preventDefault();
        event.returnValue = true;
    };

    /**
     * The creative editor receives a simple creative object from redux
     * To be able to use edit the creative we need to enrich the creative based on the type it will enrich the creative with the correct data
     * If the creative is a creative with a template linked it will enrich the creative with the template data
     * @returns
     */
    const setInitialData = async () => {
        if (externalData?.templateData) TemplateManager.addTemplate(externalData.templateData);

        const creative = await (async () => {
            if (externalData?.creativeData) {
                return {
                    type: 'template',
                    data: externalData.creativeData
                } as CreativeV2Template;
            }

            if (!creativeV2Template) return null;

            await CreativeV2Helpers.loadTemplateForCreative(creativeV2Template);
            return creativeV2Template;
        })();

        if (!creative) return;

        ComponentStore.setData('CreativeEditorV2', {
            creativeView: 'canvasFixed',
            sidebarView: 'interface',
            selectedFormats: [],
            localScopeId: uuidv4(),
            templateLoaded: true,
            creative,
            activeFrame: 'frame1',
            disabledFeatures
        });
    };

    const getStatus = (): 'noCreative' | 'loadingCreative' | 'noTemplate' | 'templateFound' => {
        if (!creativeV2Template && !creativeType) {
            return 'noCreative';
        }

        if (!templateLoaded) {
            return 'loadingCreative';
        }

        if (!TemplateManager.templateExists(creativeTemplateIdentifier)) {
            return 'noTemplate';
        }

        return 'templateFound';
    };

    const handleCloseEditor = () => {
        const changedAfterSave = ComponentStoreHelpers.getItem('CreativeEditorV2', 'changedAfterSave');
        if (changedAfterSave) return setShowCloseConfirmDialog(true);
        closeEditor();
    };

    const closeEditor = () => {
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'changedAfterSave', false);
        onClose?.();
    };

    // TODO: CREATIVES-v2: IMPLEMENT NEW DESIGN FOR LOADERS OR ERROR MESSAGES
    return (
        <Dialog open fullScreen>
            {showCloseConfirmDialog && (
                <ConfirmDialog
                    open
                    title={Translation.get('closeDialog.title', 'creatives-v2')}
                    description={Translation.get('closeDialog.description', 'creatives-v2')}
                    onClose={() => setShowCloseConfirmDialog(false)}
                    onConfirm={() => onClose?.()}
                    cancelText={Translation.get('closeDialog.closeButton', 'creatives-v2')}
                    confirmText={Translation.get('closeDialog.confirmButton', 'creatives-v2')}
                />
            )}

            {getStatus() === 'noCreative' && <div>{Translation.get('statuses.noCreativeFound', 'creatives-v2')}</div>}
            {getStatus() === 'loadingCreative' && (
                <div className="creative-editor-v2__loading-creative">
                    <CircularProgress />
                </div>
            )}
            {getStatus() === 'noTemplate' && <div>{Translation.get('statuses.noTemplateFound', 'creatives-v2')}</div>}
            {getStatus() === 'templateFound' && (
                <div className="creative-editor-v2">
                    <div className="creative-editor-v2__header">
                        <CreativeV2EditorHeader onSaveCreative={onSaveCreative} closeEditor={handleCloseEditor} />
                    </div>
                    <div className="creative-editor-v2__main">
                        {!templateLoaded && (
                            <div className="creative-editor-v2__loading-template">
                                <CircularProgress />
                            </div>
                        )}
                        {templateLoaded && (
                            <>
                                <div className="creative-editor-v2__content">
                                    <CreativeEditorV2Content />
                                </div>
                                <div className="creative-editor-v2__sidebar">
                                    <Sidebar />
                                </div>
                            </>
                        )}
                        {!disabledFeatures.includes('formatSelector') && <FormatListDrawer />}
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default CreativeEditorV2;
