import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStore, { ComponentStoreHelpers } from 'components/data/ComponentStore';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';
import { CommentButton } from 'components/ui-components-cape/CommentButton';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import { CreativeV2Template } from '../types/creativeV2.type';

import '../styles/header.scss';

interface ComponentStoreProps {
    sidebarView: CreativeEditorV2['sidebarView'];
    disabledFeatures: CreativeEditorV2['disabledFeatures'];
    creative: CreativeEditorV2['creative'];
    isSavingCreative: CreativeEditorV2['isSavingCreative'];
}

interface Props {
    closeEditor: () => void;
    showSave?: boolean;
    onSaveCreative?: (creative: CreativeV2Template) => void;
}

const CreativeV2EditorHeader: React.FC<Props> = ({ closeEditor, showSave = true, onSaveCreative }) => {
    const [availableLanguages, setAvailableLanguages] = useState<{ key: string; label: string }[]>([{ key: 'EN', label: 'English' }]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const languageSelectorOpen = Boolean(anchorEl);

    const { sidebarView, creative, disabledFeatures, isSavingCreative } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            sidebarView: 'sidebarView',
            creative: 'creative',
            disabledFeatures: 'disabledFeatures',
            isSavingCreative: 'isSavingCreative'
        }
    });

    const entity = (() => {
        const baseEntity = 'creativeEditor-';

        return `${baseEntity}${creative.id}`;
    })();

    useEffect(() => {
        const languages = EditorData.getValueFromModel('settings.languages');

        if (!languages) return;

        setAvailableLanguages(
            Object.keys(languages).map((key) => {
                return {
                    key: key,
                    label: languages[key]
                };
            })
        );
    }, []);

    const openFormatsListDrawer = () => {
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'formatsListOpen', true);
    };

    const toggleComments = () => {
        const newSidebarView = sidebarView === 'comments' ? 'interface' : 'comments';
        ComponentStore.setModel('CreativeEditorV2', 'sidebarView', newSidebarView);
    };

    const saveCreative = () => {
        const creative = ComponentStore.getItem<CreativeEditorV2['creative']>('CreativeEditorV2', 'creative');
        if (!creative) return;

        const clonedCreative: CreativeV2Template = cloneDeep(creative);

        onSaveCreative && onSaveCreative(clonedCreative);

        ComponentStoreHelpers.setMultiModels('CreativeEditorV2', [
            ['changedAfterSave', false],
            ['isSavingCreative', true]
        ]);
    };

    const openLanguageSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeLanguageSelector = () => {
        setAnchorEl(null);
    };

    const onSelectLanguage = (newLanguage: string) => {
        // We set the data in the old editor state, so that all the inputs work out of the box
        EditorData.setEditorState('language', newLanguage);
        closeLanguageSelector();
    };

    return (
        <div className="creative-editor-v2-header">
            {!disabledFeatures.includes('close') && (
                <IconButton className="creative-editor-v2-header__arrow-back" onClick={closeEditor} size="small">
                    <Icon fontSize="small">arrow_back</Icon>
                </IconButton>
            )}

            {!disabledFeatures.includes('formatSelector') && (
                <Button variant="outlined" onClick={() => openFormatsListDrawer()} startIcon={<Icon fontSize="small">list_alt_add</Icon>}>
                    {Translation.get('titles.manageFormats', 'creatives-v2')}
                </Button>
            )}

            {!disabledFeatures.includes('languages') && (
                <>
                    <Button
                        className="creative-editor-v2-header__arrow-button"
                        onClick={openLanguageSelector}
                        endIcon={<Icon fontSize="small">expand_more</Icon>}>
                        {availableLanguages.find((lang) => lang.key === EditorData.getLanguage())?.label || 'English'}
                    </Button>
                    <Menu anchorEl={anchorEl} open={languageSelectorOpen} onClose={closeLanguageSelector}>
                        {availableLanguages.map((language) => (
                            <MenuItem key={language.key} onClick={() => onSelectLanguage(language.key)}>
                                {language.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}

            <div className="creative-editor-v2-header__actions-right">
                {!disabledFeatures.includes('comments') && (
                    <CommentButton active={sidebarView === 'comments'} onClick={() => toggleComments()} entity={entity} />
                )}

                {showSave ? (
                    <Button
                        className="creative-editor-v2-header__save"
                        color="primary"
                        variant="contained"
                        onClick={saveCreative}
                        loading={isSavingCreative}
                        loadingPosition="start"
                        startIcon={<Icon fontSize="small">save</Icon>}>
                        {Translation.get('actions.save', 'common')}
                    </Button>
                ) : (
                    <IconButton className="creative-editor-v2-header__close" onClick={closeEditor} size="small">
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export { CreativeV2EditorHeader };
