import React, { useMemo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import FormatsList from '../../formats-list';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import '../styles/format-list-drawer.scss';

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
    formatsListOpen: CreativeEditorV2['formatsListOpen'];
}

const FormatListDrawer = (): JSX.Element | null => {
    const { creative, formatsListOpen } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creative: 'creative',
            formatsListOpen: 'formatsListOpen'
        }
    });

    const formatList = useMemo(() => {
        const formats = CreativeV2FormatHelpers.getFormats(creative.data.templateIdentifier);
        return formats || [];
    }, [creative.data.templateIdentifier]);

    // todo helper (formatselector has the same)
    const getFilters = (): string[] | undefined => {
        const formatSets = (TemplateManager.getTemplateByIdentifier(creative.data.templateIdentifier) as TDTemplateAsset)?.data.settings?.formatSets;

        if (!formatSets || !Array.isArray(formatSets)) return;

        return formatSets.map((format) => format.title).filter((set) => set !== 'Unset');
    };

    /**
     * Closes the formats list drawer
     */
    const closeFormatsListDrawer = () => {
        ComponentStoreHelpers.setModel('CreativeEditorV2', 'formatsListOpen', false);
    };

    return (
        <Backdrop open={formatsListOpen} onClick={() => closeFormatsListDrawer()} sx={{ zIndex: 10000 }}>
            <div className="creative-editor-v2-header__formats-list-drawer" onClick={(e) => e.stopPropagation()}>
                <FormatsList
                    items={formatList || []}
                    selectedItems={creative.data?.settings?.activeFormats}
                    filters={getFilters()}
                    onChangeSelectedItems={(newSelectedFormats: string[]) => CreativeV2FormatHelpers.updateActiveFormats(newSelectedFormats)}
                    onClose={() => closeFormatsListDrawer()}
                />
            </div>
        </Backdrop>
    );
};

export { FormatListDrawer };
