import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import { usePreviousValue } from 'hooks/usePreviousProps';
import InterfaceSetupInput from 'types/InterfaceSetupInput.type';
import { CEiframeEventPayload, EventEmitterTypes } from 'types/event-emitter.type';
import { InterfaceSetupExport } from 'types/interfaceSetupExport.type';
import Translation from 'components/data/Translation';
import { EditorLocalScope } from 'components/editor-data/EditorLocalScope';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { getCreativeInstance } from 'components/creatives-v2/helpers/creatives-factory';
import EditorData from 'components/editor-data/EditorData';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { FieldSetAction } from 'components/input/FieldSet/components/actions';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import CreativeModelsHelpers from 'components/creatives-v2/helpers/creative-models.helpers';
import { TemplateManager } from 'components/creatives-v2/data/template-manager';
import { TDTemplateAsset } from 'components/template-management/types/template-management.type';
import { MultiModel } from 'components/template-designer/data/template-designer-store';
import { FRAMESCOUNT } from 'components/creatives-v2/constants';
import MultiInput from '../../../../editor-blocks/MultiInput';
import { CreativeEditorV2 } from '../../creative-editor/types/creativeEditorV2.type';
import { BASE_BLOCK_MODEL, CreativeEditorEditHelpers } from '../helpers/creative-editor-edit.helpers';
import CreativeEditorHelpers from '../helpers/creative-editor.helpers';
import CreativeOverviewHelpers from '../../creative-overview/helpers/creative-overview.helpers';

type GetLayerStylesAction = Extract<CEiframeEventPayload['action'], { type: 'getLayerStyles' }>;
interface LayerStyles {
    width: string;
    height: string;
    layerKey: string;
}

interface ComponentStoreProps {
    creative: CreativeEditorV2['creative'];
    activeFrame: CreativeEditorV2['activeFrame'];
    selectedFormats: CreativeEditorV2['selectedFormats'];
    localScopeId: CreativeEditorV2['localScopeId'];
}

/**
 * The CreativeV2EditorEdit component handles the interface setup of a template.
 * It set ups the interface setup with correct blockModels based on the template type.
 *
 * If there are formats selected it set upts the blockModels up with overwrites paths in it
 */
const CreativeV2EditorEdit = () => {
    const [interfaceSetup, setInterfaceSetup] = useState<InterfaceSetupInput[]>([]);
    const { creative, activeFrame, selectedFormats, localScopeId } = useComponentStore<ComponentStoreProps>('CreativeEditorV2', {
        fields: {
            creative: 'creative',
            activeFrame: 'activeFrame',
            selectedFormats: 'selectedFormats',
            localScopeId: 'localScopeId'
        }
    });

    // We need to keep track of previous selected formats so we can clear the overwrite data of the formats that are unselected
    const previousSelectedFormats = usePreviousValue(selectedFormats);

    // we are getting the creativeModel here. This is a string that we will add to the blockmodel. For each template there is a different creativemodel
    // An example for display ads is frames.frame1
    const creativeModel = useMemo(() => getCreativeInstance(creative).getCreativeModel(activeFrame), [creative, activeFrame]);

    useEffect(() => {
        (async () => {
            const interfaceSetup = await getInterfaceSetup();
            if (interfaceSetup) setInterfaceSetup(interfaceSetup);
        })();
    }, [creative.data?.templateInput, selectedFormats.length, activeFrame]);

    useEffect(() => {
        // check if we can clean up the overwrite data in the data
        // when we set up overwrites for the selected formats we copy over the current data object to the overwrites
        // we do this to make sure the inputs have the correct data to show otherwise they would have been empty because the model is linked to the overwrite place
        if (Array.isArray(previousSelectedFormats) && previousSelectedFormats.length > 0) {
            CreativeEditorEditHelpers.clearOverwriteData(localScopeId, creative, previousSelectedFormats);
        }

        // if selected formats change we need to rerender the whole inteface setup as we need to update the blockmodels and linkedblockmodels
        // we are adding .overwrites. in the blockmodel to create overwrites for the selected formats
        CreativeEditorEditHelpers.setupOverwriteData(localScopeId, creative, selectedFormats);
    }, [creative.data?.templateInput, selectedFormats.length, previousSelectedFormats?.length]);

    // get the initial data of the creative
    // we need to do this because we are using the local editor data and this is the only way to load the data in
    const initialData = useMemo(() => {
        if (!creative.data?.templateInput) return {};

        const templateInput = cloneDeep(creative.data.templateInput);

        return { [BASE_BLOCK_MODEL]: templateInput };
    }, [creative.data?.templateInput, selectedFormats]);

    // There is a possibility that active frame in creative editor is undefined if so we are rendering nothing as we do not know
    // what to render
    if (activeFrame === undefined) return null;

    // We are building the interface setup here. We are adding the block models, blockmodel parent and the linked block models
    // - The block model is the path in the data object where we need to store the value of that input
    // - The block model parent is needed for the validations of an input. If an input should only be shown when the parent block model has a value we use
    // the blockModelParent to get the parent block data which we use to validate.
    // - Linked block models is an array of block models that also needs to be updated when the input changes. So that means that the block model will update
    // and all the block models that is in the linked block model array. (This is used for overwrites: In the linked block model will be the list of all
    // the selected formats except the first one because the first selected format will be set through the block model itself)
    const getInterfaceSetup = async () => {
        let interfaceSetup = (TemplateManager.getTemplateByIdentifier(creative.data?.templateIdentifier) as TDTemplateAsset)?.data.interfaceSetup;
        if (!interfaceSetup || !interfaceSetup.length) return [];

        interfaceSetup = filterInterfaceSetupByBase(interfaceSetup, activeFrame);

        let interfaceSetupItems = extractItemsFromInterfaceSetup(interfaceSetup);
        const blockModelParent = getBlockModelParent(selectedFormats);
        const linkedBlockModels = getLinkedBlockModels(selectedFormats, creativeModel);
        const blockModel = createBlockModel(blockModelParent, creativeModel);

        interfaceSetupItems = CreativeEditorEditHelpers.updateModels(interfaceSetupItems, selectedFormats, blockModel, linkedBlockModels, blockModelParent);

        const activeFormatKeys: string[] = CreativeV2FormatHelpers.getActiveFormats(creative).map((format) => format.key);
        interfaceSetupItems = await addOptionalImageSizeCropper(interfaceSetupItems, selectedFormats);

        interfaceSetupItems = updateInterfaceSetupItems(interfaceSetupItems, activeFormatKeys);
        return interfaceSetupItems;
    };

    // If activeFrame is base we only want to show the base interface setup. If not, filter base out of the interface setup
    const filterInterfaceSetupByBase = (interfaceSetup: InterfaceSetupExport[], activeFrame: string): InterfaceSetupExport[] => {
        return interfaceSetup.filter((item) => (activeFrame === 'base' ? item.path === 'base' : item.path !== 'base'));
    };

    const extractItemsFromInterfaceSetup = (interfaceSetup: InterfaceSetupExport[]): InterfaceSetupInput[] => {
        if (!interfaceSetup || !interfaceSetup.length) return [];
        return interfaceSetup.flatMap((item) => item.items);
    };

    // If there are selected formats we need to update the blockmodelparent to include the "overwrites." path.
    // We take the first one here, the rest will be in linkedBlockModels after
    const getBlockModelParent = (selectedFormats: string[]): string => {
        return selectedFormats.length ? `${BASE_BLOCK_MODEL}.overwrites.${selectedFormats[0]}` : BASE_BLOCK_MODEL;
    };

    const getLinkedBlockModels = (selectedFormats: string[], creativeModel: string): string[] | undefined => {
        if (!selectedFormats.length) return undefined;
        return selectedFormats.slice(1).map((format) => CreativeModelsHelpers.getModelPath(true, true, creativeModel, format));
    };

    const createBlockModel = (blockModelParent: string, creativeModel: string): string => {
        if (blockModelParent === '') return creativeModel;
        return creativeModel ? `${blockModelParent}.${creativeModel}` : blockModelParent;
    };

    const addOptionalImageSizeCropper = async (items: InterfaceSetupInput[], selectedFormats: string[]): Promise<InterfaceSetupInput[]> => {
        const template = TemplateManager.getTemplateByIdentifier(creative.data?.templateIdentifier) as TDTemplateAsset;
        const frameType = EditorData.getValueFromModel('data.type', undefined, `scope-${localScopeId}`) || 'main';
        const formatKey = selectedFormats[0];

        if (!template || !formatKey) return items;

        const layerProperties = cloneDeep(template?.data.templateSetup?.layerProperties);
        const combinedLayerProps = mergeWith(layerProperties?.general, layerProperties?.[formatKey], (general, format) => {
            if (Array.isArray(format) && format.length === 0) return general;
        });

        const promises: Promise<LayerStyles | null>[] = [];

        const newItems: InterfaceSetupInput[] = items.map((item) => {
            const layerKey = item.model?.split('.')[0];
            if (!layerKey || item.type !== 'assetGalleryInput' || selectedFormats.length !== 1) return item;

            const layerProps = combinedLayerProps[frameType][layerKey]?.properties;
            if (layerProps && layerProps.width && layerProps.height && layerProps.width.unit === 'px' && layerProps.height.unit === 'px') {
                return {
                    ...item,
                    outputWidth: layerProps.width.value,
                    outputHeight: layerProps.height.value,
                    useImageSize: true
                };
            } else {
                promises.push(sendMessageAndWaitForResponse({ type: 'getLayerStyles', format: formatKey, layerKey }));
                return item;
            }
        });

        if (promises.length === 0) return newItems;

        const asyncLayersWithStyling = await Promise.all(promises);

        asyncLayersWithStyling.forEach((layerStyle) => {
            if (!layerStyle) return;

            const item = newItems.find((item) => item.model?.split('.')[0] === layerStyle.layerKey);
            if (!item) return;

            newItems[newItems.indexOf(item)] = {
                ...item,
                outputWidth: Number(layerStyle.width.split('px')[0]),
                outputHeight: Number(layerStyle.height.split('px')[0]),
                useImageSize: true
            };
        });

        return newItems;
    };

    const updateInterfaceSetupItems = (interfaceSetupItems: InterfaceSetupInput[], activeFormatKeys: string[]): InterfaceSetupInput[] => {
        // For each item in the interface setup we are adding some extra actions to the items (overwrite count message, overwrite action, other actions)
        return interfaceSetupItems.map((item) => {
            // For each format, check if this specific input has an overwrite and if so, add one to the count. This will be used to show a message that
            // tells the user that if they are updating the general input (so without any formats selected), that it does not have an impact
            // on the formats with overwrites
            const inputsWithOverwrites = activeFormatKeys.reduce((count, formatKey) => {
                const overwrite = get(creative.data.templateInput, CreativeModelsHelpers.getModelPath(false, true, creativeModel, formatKey, item.model));
                return count + (overwrite ? 1 : 0);
            }, 0);

            const extraLabelText: { text: string; tooltip: string } | undefined = (() => {
                if (selectedFormats.length === 0 && activeFormatKeys.length > 1 && inputsWithOverwrites > 0) {
                    return {
                        text: `${activeFormatKeys.length - inputsWithOverwrites}/${activeFormatKeys.length}`,
                        tooltip: Translation.get('tooltips.inputsWithOverwrites', 'creatives-v2', {
                            impactOn: activeFormatKeys.length - inputsWithOverwrites,
                            total: activeFormatKeys.length,
                            inputsWithOverwrites,
                            formatsLabel:
                                inputsWithOverwrites > 1
                                    ? Translation.get('labels.formatsHave', 'creatives-v2')
                                    : Translation.get('labels.formatHas', 'creatives-v2')
                        })
                    };
                }

                return;
            })();

            const onOverwriteReset = (() => {
                if (selectedFormats.length === 0) return;

                // Only continue if one of the selected formats has an overwrite
                if (!selectedFormats.some((formatKey) => checkHasOverwrite(item, formatKey))) return;

                // Reset this overwrite for each selected format
                return () => {
                    selectedFormats.forEach((formatKey) => {
                        CreativeEditorHelpers.resetSingleOverwrite(formatKey, creativeModel, item.model?.split('.')[0] || '', localScopeId);
                    });
                };
            })();

            const actions: FieldSetAction[] | undefined = (() => {
                // Determine if we should show the extra actions (apply to all formats, apply to general only)
                if (!(selectedFormats.length === 1 && checkHasOverwrite(item, selectedFormats[0]))) return;

                return [
                    {
                        key: 'apply-to-all-formats',
                        label: Translation.get('labels.applyToAllFormats', 'creatives-v2'),
                        onClick: () => applyOverwriteToAllFormats(item, selectedFormats[0])
                    },
                    {
                        key: 'apply-to-general-only',
                        label: Translation.get('labels.applyToGeneralOnly', 'creatives-v2'),
                        onClick: () => applyOverwriteToGeneral(item, selectedFormats[0])
                    }
                ];
            })();

            // Also target the nested items (that are in groups etc)
            if (item.items) {
                item.items = updateInterfaceSetupItems(item.items, activeFormatKeys);
            }

            return {
                ...item,
                extraLabelText,
                actions,
                onOverwriteReset
            };
        });
    };

    // Send a message to the iframe to get the layer styles. We use this when we can't get the px sizes from the template itself
    // For example, if the layer is based on % and its parent(s). The event listener will automtically remove itself after the first message
    const sendMessageAndWaitForResponse = (action: GetLayerStylesAction): Promise<LayerStyles | null> => {
        return new Promise((resolve) => {
            const handleMessage = (event: MessageEvent) => {
                if (event?.data?.layerStyles?.layerKey === action.layerKey) {
                    resolve(event.data.layerStyles);
                } else {
                    resolve(null);
                }
            };

            window.addEventListener('message', handleMessage, { once: true });
            EventEmitterHelpers.sent(EventEmitterTypes.CEiframe, { action });
        });
    };

    // Check if the input item has an overwrite
    const checkHasOverwrite = (item: InterfaceSetupInput, formatKey: string): boolean => {
        if (!formatKey || !item.model) return false;

        const templateInput = creative.data?.templateInput;
        if (!templateInput || !templateInput.overwrites) return false;

        // Get the input data and the overwrites that are already in the creative. If there are overwrites and it's different from the input data, return true
        const templateInputData = get(templateInput, CreativeModelsHelpers.getModelPath(false, false, creativeModel, undefined, item.model), templateInput);
        const templateInputOverwrites = get(
            templateInput,
            CreativeModelsHelpers.getModelPath(false, true, creativeModel, formatKey, item.model),
            templateInput
        );

        if (!!templateInputOverwrites && !isEqual(templateInputData, templateInputOverwrites)) {
            return true;
        }

        return false;
    };

    // Function to apply the overwrite data to all formats
    const applyOverwriteToAllFormats = (item: InterfaceSetupInput, formatKey: string) => {
        applyOverwriteToGeneral(item, formatKey);

        const formatsWithOverwrites = get(creative, `data.templateInput.overwrites`);
        if (!formatsWithOverwrites) return;

        // Delete the overwrite for this input on each format
        Object.keys(formatsWithOverwrites).forEach((formatKey) => {
            CreativeEditorHelpers.resetSingleOverwrite(formatKey, creativeModel, item.model || '', localScopeId);
        });
    };

    // Function to apply the overwrite data to the general format
    const applyOverwriteToGeneral = (item: InterfaceSetupInput, formatKey: string) => {
        // First get the value that will be set to general
        const value = cloneDeep(get(creative.data.templateInput, CreativeModelsHelpers.getModelPath(false, true, creativeModel, formatKey, item.model)));
        if (!value) return;

        // Add to general
        EditorData.setModel(CreativeModelsHelpers.getModelPath(true, false, creativeModel, undefined, item.model), value, [], `scope-${localScopeId}`);

        // Remove the overwrite for this input, because it can just take general
        CreativeEditorHelpers.resetSingleOverwrite(formatKey, creativeModel, item.model?.split('.')[0] || '', localScopeId);
    };

    /**
     * Updates the template input in the creative. This triggers rerenders in the canvas to show the latest changes
     * @param newLocalScopeData the new template input data with overwrites
     */
    const onChangeInput = (newLocalScopeData: unknown) => {
        if (!newLocalScopeData || !Object.keys(newLocalScopeData).length) return;

        const newTemplateInput = cloneDeep(newLocalScopeData[BASE_BLOCK_MODEL]);

        if (isEqual(newTemplateInput, creative.data.templateInput)) return;

        const models: MultiModel = [];
        models.push(['creative.data.templateInput', newTemplateInput]);

        // If the users make a change to the template input, we need to set the changedAfterSave to true
        // We will exclude the cases where the template input is equal to the default empty input, so it will not be true just after you added a creative
        const isEmptyTemplateInput =
            isEqual(newTemplateInput, {
                frames: {
                    frame1: {
                        type: 'main'
                    }
                }
            }) ||
            (Object.keys(newTemplateInput).length === 1 && Object.keys(newTemplateInput)[0] === 'type');

        if (!isEmptyTemplateInput) models.push(['changedAfterSave', true]);

        let newFramesCount = 0;

        if (newTemplateInput.frames) {
            newFramesCount = Object.keys(newTemplateInput.frames).length;
            models.push([`creative.${FRAMESCOUNT}`, newFramesCount]);
        }

        ComponentStoreHelpers.setMultiModels('CreativeEditorV2', models);
    };

    return (
        <EditorLocalScope
            localScopeUuid={`scope-${localScopeId}`}
            onChange={onChangeInput}
            beforeOnChange={() => CreativeOverviewHelpers.pause()}
            initialData={initialData}>
            <MultiInput
                key={`frame-${activeFrame}`}
                data={{
                    items: interfaceSetup
                }}
            />
        </EditorLocalScope>
    );
};

export { CreativeV2EditorEdit };
