import React, { useMemo } from 'react';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import FormatChips from 'components/ui-components-cape/FormatChips';
import { CreativeEditorV2 } from '../types/creativeEditorV2.type';
import { CreativeV2EditorEdit } from './sidebar-edit';
import '../styles/sidebar-interface.scss';

interface CreativeEditorComponentStoreProps {
    selectedFormats: CreativeEditorV2['selectedFormats'];
    activeFormats: CreativeEditorV2['creative']['data']['settings']['activeFormats'];
    creativeTemplateIdentifier: CreativeEditorV2['creative']['data']['templateIdentifier'];
}

/**
 * This is the base component of the creative builder right sidebar.
 * This component handles the overwrite formats component and loads the creative editor edit component in
 */
const SidebarInterface = () => {
    const { selectedFormats, activeFormats, creativeTemplateIdentifier } = useComponentStore<CreativeEditorComponentStoreProps>('CreativeEditorV2', {
        fields: {
            selectedFormats: 'selectedFormats',
            activeFormats: 'creative.data.settings.activeFormats',
            creativeTemplateIdentifier: 'creative.data.templateIdentifier'
        }
    });
    const hasSelectedFormats = !!selectedFormats.length;

    /**
     * Deselects a format
     * @param formatKey The key of the format that we want to deselect
     */
    const deselectFormat = (formatKeys: string[]) => {
        const clonedSelectedFormats = cloneDeep(selectedFormats).filter((format) => !formatKeys.includes(format));

        ComponentStoreHelpers.setModel('CreativeEditorV2', 'selectedFormats', clonedSelectedFormats);
    };

    const formatList = useMemo(() => {
        if (!activeFormats) return [];

        return CreativeV2FormatHelpers.getFormats(creativeTemplateIdentifier, activeFormats);
    }, [creativeTemplateIdentifier, activeFormats]);

    return (
        <div className="sidebar-interface">
            {hasSelectedFormats && <FormatChips formats={formatList} selectedFormats={selectedFormats} removeFormatSelection={deselectFormat} />}
            <div className="sidebar-interface__edit">
                <CreativeV2EditorEdit />
            </div>
        </div>
    );
};

export { SidebarInterface };
